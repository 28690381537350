import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'react-intl';

export default function Social() {
  const intl = useIntl();
  const socialIcons =
    'w-11 mx-4 md:my-7 h-11 fill-current text-white opacity-70 hover:opacity-100 transition-opacity duration-300';
  const socialLinks = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          element
          github
          linkedIn
          telegram
          title
          twitter
        }
      }
    }
  `);

  return (
    <section className="h-full bg-parityPink">
      <div className="container flex flex-col md:flex-row md:justify-evenly items-center">
        <div className="my-8 md:my-0">
          <h4 className="text-white md:text-2xl opacity-90">
            {intl.formatMessage({ id: 'homepage-social-card-title' })}
          </h4>
        </div>
        <div className="flex md:mb-0 mb-8">
          <a href={socialLinks.site.siteMetadata.twitter} target="_blank" rel="noreferrer">
            <svg className={socialIcons} role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>Twitter icon</title>
              <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"></path>
            </svg>
          </a>
          <a href={socialLinks.site.siteMetadata.github} target="_blank" rel="noreferrer">
            <svg className={socialIcons} role="img" xmlns="http://www.w3.org/1999/xlink" viewBox="5 5 24 24">
              <path d="M18.944 26.128H13.743s.007-1.544 0-2.602c-3.56.766-4.553-1.951-4.553-1.951-.65-1.301-1.301-1.951-1.301-1.951-1.301-.773 0-.65 0-.65 1.301 0 1.951 1.301 1.951 1.301 1.142 1.938 3.173 1.626 3.903 1.301 0-.65.285-1.634.65-1.951-2.841-.32-5.207-1.951-5.207-5.204s.653-3.903 1.304-4.553c-.131-.321-.676-1.506.02-3.252 0 0 1.278 0 2.579 1.951.645-.645 2.602-.65 3.253-.65.649 0 2.607.006 3.251.65 1.301-1.951 2.582-1.951 2.582-1.951.695 1.747.152 2.932.02 3.252.65.65 1.301 1.301 1.301 4.553s-2.362 4.884-5.204 5.204c.366.317.65 1.437.65 1.951l.002 4.552z" />
            </svg>
          </a>
          <a href={socialLinks.site.siteMetadata.telegram} target="_blank" rel="noreferrer">
            <svg className={socialIcons} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <title>Telegram icon</title>
              <path d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 Z M14.4415206,6 C14.060553,6.00676048 13.476055,6.20741135 10.663148,7.36249773 C9.67796175,7.7670526 7.70897661,8.60437935 4.75619264,9.87447795 C4.27670659,10.0627254 4.02553067,10.2468857 4.00266485,10.4269588 C3.95876487,10.7726802 4.46291296,10.8803081 5.09723696,11.0838761 C5.61440201,11.2498453 6.31007997,11.4440124 6.67173438,11.4517262 C6.99978943,11.4587234 7.36593635,11.3251987 7.77017511,11.051152 C10.5290529,9.21254679 11.9531977,8.28322679 12.0426094,8.26319203 C12.1056879,8.24905787 12.1930992,8.23128593 12.2523244,8.28325656 C12.3115496,8.33522719 12.3057275,8.43364956 12.299454,8.46005377 C12.2492926,8.67117474 9.65764825,10.998457 9.50849738,11.1513987 L9.43697409,11.2233057 C8.88741493,11.7661123 8.33196049,12.1205055 9.290333,12.7440164 C10.155665,13.3069957 10.6592923,13.6661378 11.5507686,14.2430701 C12.1204738,14.6117635 12.5671299,15.0489784 13.1553348,14.9955401 C13.4259939,14.9709508 13.705567,14.7196888 13.8475521,13.9703127 C14.1831052,12.1993135 14.8426779,8.36209709 14.9951103,6.78087197 C15.0084653,6.64233621 14.9916649,6.46503787 14.9781732,6.38720805 C14.9646815,6.30937823 14.9364876,6.19848702 14.8340164,6.11639754 C14.7126597,6.01917896 14.5253109,5.99867765 14.4415206,6 Z"></path>
            </svg>
          </a>
          <a href={socialLinks.site.siteMetadata.element} target="_blank" rel="noreferrer">
            <svg className={`${socialIcons}`} width="100%" height="100%" viewBox="0 0 112 112" version="1.1">
              <path d="M56,112C86.928,112 112,86.928 112,56C112,25.072 86.928,0 56,0C25.072,0 0,25.072 0,56C0,86.928 25.072,112 56,112ZM66.303,85.907C66.303,88.168 64.467,90 62.202,90C46.849,90 34.404,77.58 34.404,62.259C34.404,59.999 36.24,58.167 38.505,58.167C40.77,58.167 42.606,59.999 42.606,62.259C42.606,73.059 51.38,81.814 62.202,81.814C64.467,81.814 66.303,83.647 66.303,85.907ZM85.899,45.648C88.164,45.648 90,47.48 90,49.741C90,65.061 77.554,77.481 62.202,77.481C59.937,77.481 58.1,75.649 58.1,73.388C58.1,71.128 59.937,69.295 62.202,69.295C73.024,69.295 81.797,60.54 81.797,49.741C81.797,47.48 83.633,45.648 85.899,45.648ZM26.101,66.352C23.836,66.352 22,64.52 22,62.259C22,46.939 34.446,34.519 49.798,34.519C52.063,34.519 53.9,36.351 53.9,38.612C53.9,40.872 52.063,42.705 49.798,42.705C38.976,42.705 30.203,51.46 30.203,62.259C30.203,64.52 28.367,66.352 26.101,66.352ZM45.762,26.093C45.762,23.833 47.598,22 49.863,22C65.215,22 77.661,34.42 77.661,49.741C77.661,52.001 75.825,53.834 73.56,53.834C71.295,53.834 69.458,52.001 69.458,49.741C69.458,38.941 60.685,30.186 49.863,30.186C47.598,30.186 45.762,28.353 45.762,26.093Z" />
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
}